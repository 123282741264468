import React from "react"
import { MainLayout } from "../layouts"
import { SEO } from "../components"
import styled from "styled-components"
import { COLORS } from "../constants"
import { Effect } from "../components"

const Center = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: -webkit-linear-gradient(45deg, ${COLORS.PRIMARY}, ${COLORS.SECONDARY});
  position: relative;
  overflow: hidden;
`

const ErrNumber = styled.h1`
  font-size: 10em;
  color: #fff;
  text-align: center;
  font-weight: 100;
`

const Err = styled.h1`
  font-size: 3em;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
`

const NotFoundPage = () => (
  <MainLayout>
    <SEO themeColor={COLORS.PRIMARY} title="404: Página não encontrada" />
    <Center>
      <Effect />
      <ErrNumber> 404 </ErrNumber>
      <Err> Página não encontrada. </Err>
    </Center>
  </MainLayout>
)

export default NotFoundPage
